<template>
    <v-dialog persistent
        transition="dialog-top-transition"
        v-model="open"
        max-width="400"
        @click:outside="!loading && $emit('close')"
    >
      <v-card class="pa-0 pa-3">
        <div class="d-flex align-center justify-center">
            <v-icon color="info" size="100">mdi-information-outline</v-icon>
        </div>
        <v-card-title class="flex-column justify-center pa-0 pa-3 pt-0 text-capitalize">
            <div>{{ modalData.name }}</div>
            <div class="text-subtitle-2" v-if="modalData && modalData.items && modalData.items.length !== 0">
                You have Selected to Update the following:
            </div>
        </v-card-title>
        <v-simple-table class="pa-3 mb-3">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th v-for="(header, i) in modalData.headers" :key="i" class="text-center">
                            {{ header.text }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="modalData && (!modalData.items || modalData.items.length === 0)">
                        <td colspan="3" class="text-center py-2 caption">
                            {{modalData.tableNotFound}}
                        </td>
                    </template>
                    <template v-else-if="modalData && modalData.items && modalData.items.length">
                        <tr v-for="(item, i) in modalData.items" :key="i">
                            <td v-for="(header, i) in modalData.headers" :key="i" class="text-center caption">
                                {{ item[header.value] }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-simple-table>
        <v-row class="ma-0 my-3" v-if="modalData.reason" align="center" justify="center">
            <v-col cols="3" class="pa-0">
                <v-subheader class="subtitle-1 px-0">Reason</v-subheader>
            </v-col>
            <v-col cols="6" class="pa-0">
                <v-select v-model="reason" hide-details="auto" dense class="pt-0" label="" outlined placeholder="Select Reason"
                    :items="reasonLists" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('reasonUpdate', reason)">
                </v-select>
            </v-col>
        </v-row>
        <v-card-actions class="align-center justify-center">
          <v-btn color="primary" small @click="$emit('save')" :loading="loading">
            Proceed
          </v-btn>

          <v-btn class="white--text" :disabled="loading" color="danger" small @click="$emit('close')">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['open', 'modalData', 'loading'],
    emits: ['close', 'save', 'reasonUpdate'],
    data: (vm) => ({
        reasonLists: ['Lapsed', 'Donor Request', 'Payment Holiday'],
        reason: 'Lapsed',
        actionLoading: false
    })
}
</script>
